<template>
    <b-row>
        <b-col cols="12">
            <b-tabs class="job-tabs">
                <b-tab :title="$t('jobs.tab.my') | uppercase" active id="my_jobs">
                    <b-modal class="confirm_free_job" centered
                        v-model="confirm.free.show"
                        :ok-title="$t('jobs.confirm.free.yes') | uppercase"
                        :cancel-title="$t('jobs.confirm.free.no') | uppercase"
                        cancel-variant="light"
                        ok-variant="danger"
                        :hide-header="true"
                        @ok="freeJob(confirm.free.id)">
                            {{ $t('jobs.confirm.free.text') }}
                    </b-modal>

                    <b-row class="justify-content-center">
                        <b-col cols="12">
                            <Jobs v-if="myJobs.length" :jobs="myJobs">
                                <template v-if="job.cancel_by_employee_allowed == 'TRUE'" slot="job-button" slot-scope="{job}">
                                    <b-button @click="confirm.free = {show: true, id: job.ord_job_id}"/> 
                                </template>
                            </Jobs>
                            <b-alert v-else show class="no-items-alert" variant="primary">{{ $t('jobs.myJobsEmpty') }}</b-alert>
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab :title="$t('jobs.tab.free') | uppercase" id="free_jobs">
                    <b-modal class="confirm_occupy_job" centered
                        v-model="confirm.occupy.show"
                        :ok-title="$t('jobs.confirm.occupy.yes') | uppercase"
                        :cancel-title="$t('jobs.confirm.occupy.no') | uppercase"
                        cancel-variant="light"
                        ok-variant="success"
                        :hide-header="true"
                        @show="prepareShiftTimes(confirm.occupy.job)"
                        @ok="occupyJob(confirm.occupy.id)">
                            <div>
                                <span>{{ $t('jobs.confirm.occupy.firstText') }}<span v-if="!confirm.occupy.job">?</span> </span>
                                <span v-if="confirm.occupy.job">
                                    {{ confirm.occupy.job[jobCompanyDisplayField] | uppercase }}
                                    <b>
                                      {{ confirm.occupy.job.week_day }}
                                      <span v-if="confirm.occupy.job.shift_time_label_type == 'SHIFT_TIME'">
                                        {{ confirm.occupy.job.work_date_from | moment('DD.MM.') }}
                                        {{ confirm.occupy.job.shift_time_from | time }} -
                                        {{ confirm.occupy.job.shift_time_until | time }}?
                                      </span>
                                      <span v-if="confirm.occupy.job.shift_time_label_type == 'SHIFT_CYCLE'">
                                        {{ confirm.occupy.job.work_date_from | moment('DD.MM.') }}
                                        {{ confirm.occupy.job.shift_cycle }}?
                                      </span>
                                    </b>
                                </span>
                                <span>{{ $t('jobs.confirm.occupy.secondText') }} </span>
                            </div>
                            
                            <div class="confirm_start_time" v-if="confirm.occupy.job && (confirm.occupy.job.shift_time_from_select_bool || confirm.occupy.job.shift_time_until_select_bool)">
                                {{ $t('jobs.confirm.occupy.startTime') }}
                                <b-form-select v-if="confirm.occupy.job.shift_time_from_select_bool" v-model="shift_time_from" :options="getTimeOptions(confirm.occupy.job.shift_time_from_lov)" />
                                <b-form-input v-if="!confirm.occupy.job.shift_time_from_select_bool" :value="confirm.occupy.job.shift_time_from|time" :disabled="true" />
                            </div>
                            <div class="confirm_start_time" v-if="confirm.occupy.job && (confirm.occupy.job.shift_time_from_select_bool || confirm.occupy.job.shift_time_until_select_bool)">
                                {{ $t('jobs.confirm.occupy.endTime') }}
                                <b-form-select v-if="confirm.occupy.job.shift_time_until_select_bool" v-model="shift_time_until" :options="getTimeOptions(confirm.occupy.job.shift_time_until_lov)" />
                                <b-form-input v-if="!confirm.occupy.job.shift_time_until_select_bool" :value="confirm.occupy.job.shift_time_until|time" :disabled="true" />
                            </div>
                    </b-modal>

                    <b-row class="justify-content-center">
                        <b-col cols="12">
                            <Jobs v-if="freeJobs.length" :jobs="freeJobs">
                                <template slot="job-button" slot-scope="{job}">
                                    <b-button @click="confirm.occupy = {show: true, id: job.ord_job_id, job: job}"/>
                                </template>
                            </Jobs>
                            <b-alert v-else show class="no-items-alert" variant="primary">{{ $t('jobs.freeJobsEmpty') }}</b-alert>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
/* eslint-disable */
import Jobs from '@/components/Jobs.vue'
import {CONFIG} from '../config.js'
import axios from 'axios';

export default {
        name: 'jobs',
        components: { Jobs },
        data: function() {
            return {
                confirm: {
                    free: {show: false, id: null},
                    occupy: {show: false, id: null}
                },
                shift_time_from: null,
                shift_time_until: null,
                jobCompanyDisplayField: CONFIG.jobCompanyDisplayField,
            }
        },
        watch: {
            // clear job id if the confirm dialog was closed
            "confirm.free.show": function(v) {
                if (v === false) {
                    this.confirm.free.id = null;
                }
            },
            // clear job id if the confirm dialog was closed
            "confirm.occupy.show": function(v) {
                if (v === false) {
                    this.confirm.occupy.id = null;
                }
            }
        },
        computed: {
            freeJobs: function() {
                return this.$store.state.freeJobs;
            },
            myJobs: function() {
                return this.$store.state.myJobs;
            }            
        },
        mounted: function() {
            if (this.$store.getters.isLoggedIn && !this.$store.isTokenExpired) {
                this.loadAllJobs()
            }
            this.$ebus.$emit("title:set", this.$t("menu.jobs"))
        },
        destroyed: function() {
            this.$ebus.$emit("title:reset")
        },
        methods: {
            freeJobsRequest: function(keepMessages = false) {
                var self = this;

                return this.$protected("get", CONFIG.api.endpoints.freeJobs,
                    function (response) {
                        self.$store.commit('setFreeJobs', response.data);
                    }, 
                    { keepMessages: keepMessages,
                      headers: {
                        'Accept-Profile': 'work_agency'
                      }
                    }
                );
            },
            myJobsRequest: function(keepMessages = false) {
                var self = this;

                return this.$protected("get", CONFIG.api.endpoints.myJobs,
                    function (response) {
                        self.$store.commit('setMyJobs', response.data);
                    },
                    { 
                        keepMessages: keepMessages,
                        headers: {
                            'Accept-Profile': 'work_agency'
                        }
                    });
            },
            loadAllJobs: function(keepMessages = false) {
                axios.all([this.freeJobsRequest(keepMessages), this.myJobsRequest(keepMessages)])
            },
            freeJob: function(id) {
                var self = this;

                this.$protected("post", CONFIG.api.endpoints.freeJob,
                        function () {
                            //self.$ebus.$emit('msg:new', {type: "message-modal", data: {text: self.$t("jobs.success.free")}});
                        },
                        { 
                            data: { ord_job_id_: id },
                            headers: {
                                'Content-Profile': 'work_agency'
                            }
                        })
                    .then(function() {
                        self.loadAllJobs(true);
                    });
            },
            occupyJob: function(id) {
                var self = this;
                var data = { ord_job_id_: id }
                // if (this.shift_time_from) data.shift_time_from_ = this.shift_time_from
                // if (this.shift_time_until) data.shift_time_until_ = this.shift_time_until
                data.shift_time_from_ = this.shift_time_from
                data.shift_time_until_ = this.shift_time_until
                
                this.$protected("post", CONFIG.api.endpoints.occupyJob,
                        function () {
                            //self.$ebus.$emit('msg:new', {type: "message-modal", data: {text: self.$t("jobs.success.occupy")}});
                        },
                        { 
                            data: data,
                            headers: {
                                'Content-Profile': 'work_agency'
                            }
                        })
                    .then(function() {
                        self.loadAllJobs(true);
                    });
            },
            prepareShiftTimes: function(job) {
                this.shift_time_from = null
                this.shift_time_until = null
            },
            getTimeOptions(time_lov) {
                if (!time_lov) return
                return time_lov.map((item) => { return { value: item, text: item.substring(0, 5)} })
            }
        },
        filters: {
            uppercase: function(v) {
                if (!v) return
                return v.toUpperCase();
            },
            time: function(v) {
                if (!v) return
                return v.substring(0, 5)
            }
        }
    }
</script>

<style lang="scss">
    @import 'src/scss/main';
    
    .job-tabs > div:nth-child(1) {
        margin-right: -15px;
        margin-left: -15px;
    }

    .tabs {
        .nav.nav-tabs {
            border: none;
            background-color: var(--primary) !important;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.40);

            .nav-item {
                width: 50%;
                text-align: center;
                font-weight: $font-bold;
            }

            .nav-link {
                border: none;
                border-radius: 0;
                background-color: transparent;
                color: #FFF;
                display: inline-block;                
                font-size: 14px;
                font-weight: $font-bold;
            }

            .nav-link.active {
                border-bottom: 2px solid #FFF;
            }
        }


        #free_jobs .job button {
            background: transparent url('../../public/img/icons/plus.png') no-repeat 0 0;
        }

        #my_jobs .job button {
            background: transparent url('../../public/img/icons/remove.png') no-repeat 0 0;
        }

        .job button {
            border: none;
            padding: 0 !important;
            margin: 0;
            width: 32px;
            height: 32px;
        }
    }

    .modal-body {
        color: $dark;
    }

    .modal-footer {
        border-top: none;
    }

    .modal-footer .btn-light:not(:hover) {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    @include media-breakpoint-down(xs) {
        .tabs {
            .nav.nav-tabs {
                .nav-link {
                    font-size: 14px;
                }
            }
        }       
    }

    .no-items-alert {
        margin-top: $container-top-margin;
    }
    .confirm_start_time {
        margin-top: 20px;
    }
</style>