<template>
    <b-row cols="3" class="job">
        <b-col cols="6" >
            <div class="company">{{ job[jobCompanyDisplayField] | uppercase }}</div>
            <span v-if="showWorkType">
                <div class="desc">{{ job.work_type_db | lowercase}}</div>
            </span>
        </b-col>

        <b-col cols="4" class="text-right">
            <div>
                <span class="day" v-if="weekdayFormat == 'long'">
                    {{ job.week_day }}
                </span>
                <span v-else>
                    {{ job.week_day.substring(0, 2) }}
                </span>
                <span class="date">{{ job.work_date_from | moment("DD. MM. YYYY") }}</span></div>
            <div class="time">
                <span v-if="job.shift_time_label_type == 'SHIFT_CYCLE'">
                    {{ job.shift_cycle }}
                </span>
                <span v-if="job.shift_time_label_type == 'SHIFT_TIME'">
                    {{ [job.shift_time_from, "HH:mm:ss"] | moment("HH:mm") }} - {{ [job.shift_time_until, "HH:mm:ss"] | moment("HH:mm") }}
                </span>
            </div>
        </b-col>

        <b-col cols="2" align-self="center" class="text-center">
            <slot name="job-button"></slot>
        </b-col>
    </b-row>
</template>

<script>
import {CONFIG} from "../config"

export default {
    data: function() {
        return {
            jobCompanyDisplayField: CONFIG.jobCompanyDisplayField,
            weekdayFormat: CONFIG.weekdayFormat,
            showWorkType: CONFIG.showWorkType
        }
    },
    props: ["job"],
    filters: {
        uppercase: function(v) {
            return v == null ? null : v.toUpperCase();
        },
        lowercase: function(v) {
            return v == null ? null : v.toLowerCase();
        }
    }
}
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .job {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;

        .company {
            color: $dark;
            font-weight: $font-medium;
        }

        .desc {
            color: $light;
            font-weight: $font-regular;
        }

        .day {
            font-weight: $font-medium;
            color: $light;
            margin-right: 10px;
            display: inline-block;
        }

        .date {
            font-weight: $font-medium;
            color: $dark;
            display: inline-block;
        }

        .time {
            font-weight: $font-regular;
            color: $dark;
            font-size: 12px;
        }

        > div {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .company, .day, .date {
            margin-bottom: 5px;
        }
    }

    @include media-breakpoint-down(md) {
        .job {
            font-size: 14px;

            .time {
                font-size: 12px;                
            }
        }        
    }

    @include media-breakpoint-down(xs) {
        .job {
            font-size: 14px;

            .time {
                font-size: 12px;                
            }
        }        
    }
</style>