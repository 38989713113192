<template>
    <div class="job-list">
        <Job v-for="j in jobs" :job="j" :key="j.ord_job_id">
            <template slot="job-button">
                <slot name="job-button" v-bind:job="j"></slot>
            </template>
        </Job>
    </div>
</template>

<script>
import Job from '@/components/Job.vue'

export default {
    props: [ "jobs" ],
    components: { Job }
}
</script>

<style scoped lang="scss">
    
</style>